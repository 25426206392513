<template>
    <div class="ticket">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">开票记录</div>
            <div class="toolBar">
                <h4 style="padding: 0 10px">开票日期</h4>
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                ></el-date-picker>
                <h4 style="padding: 0 10px">状态</h4>
                <el-select
                    placeholder="请选择发票状态"
                    v-model="ticketStatusType"
                >
                    <el-option
                        v-for="item in ticketStatusTypeSelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-button
                    class="red"
                    @click="handleTable()"
                    style="margin-left: 10px; height: 40px"
                >
                    查询
                </el-button>
                <el-link
                    style="color: var(--RED-C11C20); margin: 0 10px"
                    :underline="false"
                    icon="el-icon-download"
                    @click="handleTable('export')"
                >
                    导出
                </el-link>
            </div>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    type="index"
                    width="50"
                    label="序号"
                ></el-table-column>
                <el-table-column prop="type" label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            v-if="handleType[scope.row.type]"
                            :type="handleType[scope.row.type].type"
                            disable-transitions
                        >
                            {{ handleType[scope.row.type].name || "-" }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="amnount" label="开票金额" align="center">
                    <template slot-scope="scope">
                        <PayMentMoneyShow
                            :money="
                                Math.abs((scope.row.amnount || 0) / payRate)
                            "
                            type="coin"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="invoiceType"
                    label="发票类别"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-tag
                            v-if="handleInvoiceType[scope.row.invoiceType]"
                            :type="
                                handleInvoiceType[scope.row.invoiceType].type
                            "
                            disable-transitions
                        >
                            {{ handleInvoiceType[scope.row.invoiceType].name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="createTime"
                    label="发票申请时间"
                ></el-table-column>
                <el-table-column
                    prop="postTime"
                    label="发票开具时间"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.postTime || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="op" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            v-if="scope.row.fileUrl"
                            type="error"
                            class="red"
                            @click="handleDownload(scope.row.fileUrl)"
                        >
                            下载
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 0"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import {
    makeInvoice,
    getCompanyInvoiceRecordDetails,
    exportRecord,
    FEST_URL,
    getCompanyInvoiceRecord,
} from "@/api/pay";
import Moment from "moment";

import {
    ticketInfoSetting,
    ticketStatus,
    ticketClass,
    ticketDetailTicketInfo,
    ticketDetailDeliverInfo,
    payRate,
} from "@/utils/pay-data-list";
import { downLoadFile } from "@/utils";
import PayMentMoneyShow from "./PayMentMoneyShow.vue";
export default {
    components: { PayMentMoneyShow },
    data() {
        return {
            ticketType: null,
            ticketTypeSelect: [
                ...ticketClass.map((item, index) => ({
                    value: index,
                    label: item.name,
                })),
                {
                    value: null,
                    label: "全部",
                },
            ],
            ticketStatusType: null,
            ticketStatusTypeSelect: [
                ...ticketStatus.map((item, index) => ({
                    value: index,
                    label: item.name,
                })),
                {
                    value: null,
                    label: "全部",
                },
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            date: "",
            table: [],
            ticketDialogVisible: false,
            ticketLookDialogVisible: false,
            concatVisible: false,
            ticketInfoSetting: ticketInfoSetting,
            companyName: "",
            tableData: [],
            rules: {},
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            tableLoading: false,
            ticketInfoDetail: [],
            deliverInfoDetail: [],
            lastParam: "",
            payRate,
        };
    },
    computed: {
        handleType() {
            return ticketStatus;
        },
        handleInvoiceType() {
            return ticketClass;
        },
        filterTicket() {
            return this.filterToValue(this.handleInvoiceType);
        },
        filterType() {
            return this.filterToValue(this.handleType);
        },
    },
    methods: {
        // 获取默认时间
        defaultTime() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            this.date = [start, end];
        },
        // 根据类型筛选
        filterToValue(ele) {
            return ele.map((item, index) => ({
                text: item.name,
                value: index,
            }));
        },

        openTicketInfoDialog() {
            this.$refs.payMentTicketInfoRef.init();
        },
        handleLookDetail(id) {
            getCompanyInvoiceRecordDetails({ id }).then(({ data }) => {
                this.ticketLookDialogVisible = true;
                const transData = {
                    ...data.data,
                    ...data.data.companyInvoiceVO,
                };
                this.ticketInfoDetail = ticketDetailTicketInfo.map((item) => ({
                    label: item.label,
                    value:
                        item.prop === "invoiceType"
                            ? ticketClass[transData[item.prop]]?.name ?? ""
                            : transData[item.prop] ?? "",
                    width: item.width ?? "33.3%",
                }));
                this.deliverInfoDetail = ticketDetailDeliverInfo.map(
                    (item) => ({
                        label: item.label,
                        value: transData[item.prop] ?? "",
                        width: item.width ?? "33.3%",
                    }),
                );
            });
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleTable();
        },
        handleTable(type = "query") {
            const distanceDate = Moment(this.date[1]) - Moment(this.date[0]);
            const perDayTime = 3600 * 1000 * 24;
            if (distanceDate / perDayTime > 90) {
                this.$message.error("最大时间间隔不能超过90天");
                return;
            }
            const param = {
                startTime:
                    Moment(this.date[0]).format("yyyy-MM-DD") + " 00:00:00",
                endTime:
                    Moment(this.date[1]).format("yyyy-MM-DD") + " 23:59:59",
                type: this.ticketStatusType,
                invoiceType: this.ticketType,
                pageSize: this.pageSize,
                pageNum: this.pageNo,
            };
            this.tableLoading = true;
            type === "query" &&
                getCompanyInvoiceRecord(param)
                    .then(({ data }) => {
                        this.lastParam = param;
                        if (data.data) {
                            this.tableData = data.data.list;
                            this.totalNum = data.data.total;
                        } else {
                            this.tableData = [];
                            this.totalNum = 0;
                        }
                    })
                    .finally(() => {
                        this.tableLoading = false;
                    });
            type === "export" &&
                exportRecord(this.lastParam)
                    .then(({ data }) => {
                        downLoadFile(FEST_URL + data.data);
                    })
                    .finally(() => {
                        this.tableLoading = false;
                    });
        },

        // 申报
        handleDeclare(id) {
            makeInvoice({ id: id, type: 1 }).then(() => {
                this.$message.success("申报成功");
                this.handleTable();
            });
        },

        // 下载
        handleDownload(url) {
            window.open(url);
        },
    },
    mounted() {
        this.defaultTime();
        this.handleTable();
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}
.ticket {
    min-width: 1300px;
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.toolBar {
    display: flex;
}
</style>
